.auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.svg-wrapper {
  max-height: 30%;
}

.svg-wrapper circle {
  stroke: #00a6b9;
  fill: none;
  animation-name: pulse;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: running;
  transform-origin: center center;
}

.svg-wrapper .circle-1 {
  animation-delay: -4s;
}
.svg-wrapper .circle-2 {
  animation-delay: -3.5s;
}
.svg-wrapper .circle-3 {
  animation-delay: -3s;
}
.svg-wrapper .circle-4 {
  animation-delay: -2.5s;
}
.svg-wrapper .circle-5 {
  animation-delay: -2s;
}
.svg-wrapper .circle-6 {
  animation-delay: -1.5s;
}
.svg-wrapper .circle-7 {
  animation-delay: -1s;
}
.svg-wrapper .circle-8 {
  animation-delay: -0.5s;
}
.svg-wrapper .circle-9 {
  animation-delay: 0s;
}

@keyframes pulse {
  0% {
    stroke-width: 5.48px;
    transform: translate(793px, 370px) scale(0);
  }

  100% {
    stroke-width: 10.28px;
    transform: translate(0, 0) scale(1);
  }
}
