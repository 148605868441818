.app {
  display: flex;
  flex-direction: column;
  height: 100%;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.align-content-right {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}
